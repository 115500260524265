<template>
    <div
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 relative">
        <div
            ref="banner"
            class="flex p-0.5">
            <div class="flex w-full items-start p-4">
                <div class="flex-shrink-0">
                    <Icon
                        :name="icon"
                        class="h-6 w-6"
                        :class="textColor"
                        aria-hidden="true" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm font-medium text-gray-900 dark:text-white">
                        {{ notification.title }}
                    </p>
                    <p class="mt-1 text-sm text-gray-500 dark:text-gray-200">
                        {{ notification.message }}
                    </p>
                </div>
                <div
                    v-if="!notification.action"
                    class="ml-4 flex flex-shrink-0">
                    <button
                        type="button"
                        class="inline-flex rounded-md bg-white dark:bg-gray-700 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 dark:ring-offset-gray-700"
                        @click="$emit('dismiss', {id: notification.id})">
                        <span class="sr-only">Close</span>
                        <Icon
                            name="heroicons:x-mark"
                            class="h-5 w-5"
                            aria-hidden="true" />
                    </button>
                </div>
            </div>
            <div
                v-if="notification.action"
                class="flex border-l border-gray-200">
                <button
                    type="button"
                    class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                    @click="notification.action.callback(); $emit('dismiss', {id: notification.id})">
                    {{ notification.action.name }}
                </button>
            </div>
            <div
                v-if="!notification.permanent"
                class="absolute bottom-0 left-0 right-0 h-1 transition-all ease-linear"
                :class="bgColor"
                :style="`width: ${100 - timeoutProgress}%`" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type {Notification} from "../../composables/useNotification"

const props = defineProps<{
    notification: Notification
}>()

const emit = defineEmits(['dismiss'])

const textColorMap = new Map([
    ["success", "text-success-400"],
    ["info", "text-info-400"],
    ["warning", "text-warning-400"],
    ["error", "text-danger-400"]
])

const textColor = computed(() => {
    return textColorMap.get(props.notification.type)!
})

const bgColorMap = new Map([
    ["success", "bg-success-300"],
    ["info", "bg-info-300"],
    ["warning", "bg-warning-300"],
    ["error", "bg-danger-300"]
])

const bgColor = computed(() => {
    return bgColorMap.get(props.notification.type)!
})

const iconMap = new Map([
    ["success", "heroicons:check-circle"],
    ["info", "heroicons:information-circle"],
    ["warning", "heroicons:exclamation-circle"],
    ["error", "heroicons:exclamation-triangle"]
])

const icon = computed(() => {
    if (props.notification.icon) {
        return props.notification.icon
    }
    return iconMap.get(props.notification.type)!
})

const timeoutProgress = ref(0)
const INTERVAL = 150

onMounted(() => {
    if (props.notification.permanent) return
    startTimeout()
})

let remainingTime = props.notification.timeout
let timeoutRunning = false

const startTimeout = () => {
    timeoutRunning = true
    const intervalId = setInterval(() => {
        // If the element is hovered (and pauseOnHover isn't false), stop the timeout
        if (elementHovered.value && props.notification.pauseOnHover) {
            clearInterval(intervalId)
            timeoutRunning = false
            return
        }

        // If the element is not hovered, continue the timeout
        remainingTime -= INTERVAL
        timeoutProgress.value = ((props.notification.timeout - remainingTime) / props.notification.timeout) * 100
        if (timeoutProgress.value >= 100) {
            clearInterval(intervalId)
            emit("dismiss", {id: props.notification.id}, INTERVAL)
        }
    }, INTERVAL)
}

const banner = ref<HTMLElement | null>(null)

const elementHovered = useElementHover(banner)

watch(elementHovered, (value) => {
    if (!timeoutRunning && !value && !props.notification.permanent) {
        startTimeout()
    }
})
</script>