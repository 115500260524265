export default {
    title: 'Nutzer',
    email: 'E-Mail',
    customName: 'Anzeigename',
    firstname: 'Vorname',
    role: 'Rolle',
    role_name: 'Rolle',
    created_at: 'Erstellt am',
    id: 'ID',
    impersonate: 'Support geben',
    organisationName: 'Organisationsname',
    organisation: 'Organisation',

    qf_onlyEmployees: 'Nur Mitarbeiter'
}
