import { default as indexBXWxqAX4SAMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/auth/index.vue?macro=true";
import { default as indexSqQQbA7CpfMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexEFeqIfIAdGMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/index.vue?macro=true";
import { default as organisationBaseData7yEX0YgxjpMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_935Il1hTVgIBMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations/[id].vue?macro=true";
import { default as indexlRQ9A1CuHtMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations/index.vue?macro=true";
import { default as organisationswzv89BvNIQMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations.vue?macro=true";
import { default as dungeonf0K0ZwuXwvMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsawkoJNJAjNMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/secret/index/inputs.vue?macro=true";
import { default as index4E1794eTFkMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/secret/index.vue?macro=true";
import { default as languageAlxqdVIrQkMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/language.vue?macro=true";
import { default as _91id_93bcuxewKsxHMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexTBpM3y2jVdMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93Kwolnfv4xXMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexREh19mmAD7Meta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsHqrCjc7hTGMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings.vue?macro=true";
import { default as userActivities69NneiHAX0Meta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatahblCI6LSvLMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsJhfvB7FoEtMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93XilLdExxG2Meta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id].vue?macro=true";
import { default as index1u6EQ721JsMeta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/index.vue?macro=true";
import { default as usersX0VudSnQh2Meta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93fS0K5GPqB1Meta } from "/home/forge/base.farbcode.net/releases/20241106141804/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexBXWxqAX4SAMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexSqQQbA7CpfMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisationswzv89BvNIQMeta?.name,
    path: "/organisations",
    meta: organisationswzv89BvNIQMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_935Il1hTVgIBMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexlRQ9A1CuHtMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsHqrCjc7hTGMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93bcuxewKsxHMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexTBpM3y2jVdMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93Kwolnfv4xXMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexREh19mmAD7Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersX0VudSnQh2Meta?.name,
    path: "/users",
    meta: usersX0VudSnQh2Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93XilLdExxG2Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDatahblCI6LSvLMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: index1u6EQ721JsMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93fS0K5GPqB1Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241106141804/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]