export const dashboard: Dashboard = {
    rowHeight: 175,
    editable: true,
    columns: {
        'xxs': 1,
        'xs': 2,
        'sm': 3,
        'md': 4,
        'lg': 4,
    },
    widgets: [{
        name: 'demo.openAi',
        resizable: false
    }, {
        module: 'users',
        name: 'general.tableIndex',
        label: 'admins.tableIndex',
        x: 0,
        y: 1,
        height: 2,
        props: {
            module: 'users',
            detail: 'users-id-userBaseData',
            title: 'dashboard.widgets.admins.title',
            infoProp: 'email',
            nameProp: 'fullname',
            filter: [{
                name: 'role',
                column: 'users.role_name',
                operator: 'in',
                value: ['admin']
            }]
        }
    }, {
        module: 'organisations',
        name: 'general.tableIndex',
        label: 'organisations.tableIndex',
        x: 1,
        y: 1,
        height: 2,
        props: {
            module: 'organisations',
            detail: 'organisations-id-organisationBaseData',
            infoProp: 'email',
        }
    }],
}