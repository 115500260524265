import info from './info'
import menu from './menu'
import table from './table'

export default {
    organisationId: 'Organisation {id}',

    name: 'Name',
    email: 'E-Mail',
    membersCount: 'Mitglieder',

    deleteOrganisation: 'Organisation löschen',
    deleteOrganisationText:
        'Möchtest du die Organisation wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrganisationSuccess: 'Organisation wurde erfolgreich gelöscht.',
    deleteOrganisationError: 'Die Organisation konnte nicht gelöscht werden.',

    toggleState: 'aktivieren / deaktivieren',
    toggleStateSuccess: 'Status wurde erfolgreich geändert.',
    toggleStateError: 'Der Status konnte nicht geändert werden.',

    info,
    menu,
    table
}