import auth from './auth'
import dashboard from './dashboard/main'
import files from './files'
import globalSearch from './globalSearch'
import loginProviders from './loginProviders/main'
import menu from './menu'
import organisations from './organisations/main'
import pageTitle from './pageTitle'
import requestLog from './requestLog'
import secret from './secret'
import settings from './settings'

import table from './table/main'

import testUsers from './testUsers/main'
import users from './users/main'
import wiki from './wiki'

export default {
    localeHint: 'This is the English version of the website.',
    canNotAccessRoute: 'You do not have permission to access this page.',

    serverError: 'An error occurred on the server.',
    serverErrorSupport: 'If this error keeps reappearing please contact the support.',

    invalidRequestData: 'The request contains invalid data.',
    modelDoesNotExist: 'The requested resource does not exist.',
    unauthorizedRequest: 'You are not authorized to access this page.',

    baseData: 'Base Data',

    resourceDoesNotExist: 'The requested entry does not exist, or you do not have permission to retrieve it.',

    abort: 'Cancel',
    delete: 'Delete',
    back: 'Back',
    save: 'Save',
    discard: 'Discard',
    error: 'Error',
    open: 'Open',
    show: 'Show',
    more: 'More',
    change: 'Change',
    add: 'Add',
    welcome: 'Welcome!',

    pleaseSelect: 'Please select',

    locales: {
        de: 'Deutsch',
        en: 'English'
    },

    menu,
    files,
    globalSearch,
    requestLog,
    settings,
    auth,
    pageTitle,
    wiki,
    secret,
    dashboard,

    table,

    users,
    organisations,
    loginProviders,
    testUsers
}
