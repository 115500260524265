import {defineStore} from 'pinia'

export const useAuthStore = defineStore('auth', {
    // convert to a function
    state: () => ({
        isAuthenticated: null,
        abilities: [],
        userData: {
            id: 0,
            firstname: '',
            lastname: '',
            organisation_name: '',
            organisation_id: 0,
            organisation_token: '',
            email: '',
            image: ''
        },
        shake: false,
        remoteVersion: null,
        impersonate: false
    }),

    actions: {
        //https://pinia.vuejs.org/core-concepts/actions.html#subscribing-to-actions
        async successfulLogin(redirectPath = '/', query = {}) {
            return await new Promise(async (resolve, reject) => {
                try {
                    this.isAuthenticated = true
                    await this.getAuthData()
                    navigateTo({
                        path: redirectPath,
                        query: query
                    }, {external: true})
                    resolve(true)
                } catch (error) {
                    reject(error)
                }
            })
        },

        async getAuthData() {
            const response = await $larafetch(useNuxtApp().$apiRoute('spa.authData'))
            this.setAuthData(response)
        },

        setAuthData(authData) {
            this.isAuthenticated = true
            this.abilities = authData.abilities
            this.userData = authData.userData
            this.impersonate = authData.impersonate

            localStorage.setItem('isAuthenticated', 'true')
            localStorage.setItem('abilities', JSON.stringify(authData.abilities))
            localStorage.setItem('userData', JSON.stringify(authData.userData))
        },

        setRemoteVersion(version) {
            this.remoteVersion = version
        },

        resetAuthData() {
            this.isAuthenticated = false
            this.abilities = []
            this.userData = null
            this.impersonate = false

            localStorage.setItem('isAuthenticated', null)
            localStorage.setItem('abilities', null)
            localStorage.setItem('userData', null)
        },

        async logout(redirectPath = '/auth') {
            await $lara.post('/app/spa/logout')
            this.resetAuthData()
            navigateTo({
                path: redirectPath
            })
        }
    }
})
